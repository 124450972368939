import { ReactComponent as ReaktorLogo } from '../assets/logo.svg';

const Login = () => {
	return (
		<div className='login-wrapper'>
			<div className='login-body'>
				<div className='reaktor-logo'>
					<ReaktorLogo></ReaktorLogo>
				</div>
				<div className='login-header'>
					<h1 className='login-title'>Log in to your account</h1>
					<h2 className='login-subtitle'>
						Welcome back! Please enter your details
					</h2>
				</div>
				<form
					className='login-form'
					onSubmit={(e) => e.preventDefault()}
				>
					<div className='reaktor-input email-input'>
						<label htmlFor='email-input'>Email</label>
						<input
							type='email'
							name='email'
							id='email-input'
							placeholder='Enter your email'
						/>
					</div>
					<div className='reaktor-input password-input'>
						<label htmlFor='password-input'>Password</label>
						<input
							type='password'
							name='password'
							id='password-input'
							placeholder='Password'
						/>
					</div>
					<div className='remember-forget-wrapper'>
						<div className='remember-wrapper'>
							<input type='checkbox' />
							<div className='remember'>Remember for 30 days</div>
						</div>
						<div className='forgot'>Forgot password</div>
					</div>
					<button
						className='reaktor-button green-button'
						onClick={(e) => e.preventDefault()}
					>
						Sign up
					</button>
					<button
						className='reaktor-button'
						onClick={(e) => e.preventDefault()}
					>
						Log in with something weird
					</button>
				</form>
				<div className='login-footer'>
					Don't have an account?&nbsp;
					<span className='green-text'>Sign up</span>
				</div>
			</div>
		</div>
	);
};

export default Login;
