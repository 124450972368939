import './App.scss';
import { Routes, Route } from 'react-router-dom';
import Nav from './components/Nav';
import Home from './components/Home';
import Login from './components/Login';

function App() {
	return (
		<div className='App'>
			<header className='site-header'>
				<Nav />
			</header>
			<main className='site-main'>
				<Routes>
					<Route
						path='/login'
						element={<Login />}
					/>
					<Route
						path='/'
						element={<Home />}
					/>
				</Routes>
			</main>
		</div>
	);
}

export default App;
