const Home = () => {
	return (
		<div className='home-wrapper'>
			<div className='hero-wrapper'>
				<div className='green-text'>Current as of 20 Jan 2022</div>
				<h1 className='hero-title'>Privacy Policy</h1>
				<h2 className='hero-subtitle'>
					Your privacy is important to us at Lorem Ipsum. We respect
					your privacy regarding any information we may collect from
					you across our website.
				</h2>
			</div>
			<div className='home-body-wrapper'>
				<p className='home-body-paragraph'>
					Win-win-win baseline the procedure and samepage your
					department. Donuts in the break room out of scope. Going
					forward. Hammer out. All hands on deck hit the ground
					running. Shoot me an email i have a hard stop in an hour and
					half service as core &innovations as power makes our brand.
				</p>
				<p className='home-body-paragraph'>
					We need to follow protocol on your plate, so it's about
					managing expectations problem territories. Can we align on
					lunch orders ramp up. Make it look like digital. A better
					understanding of usage can aid in prioritizing future
					efforts. This is not a video game, this is a meeting!.
				</p>
				<h3 className='home-body-title'>
					What information do we collect?
				</h3>
				<p className='home-body-paragraph'>
					Bacon ipsum dolor amet ground round chislic pork chop,
					kielbasa t-bone tail spare ribs jerky prosciutto bacon swine
					jowl boudin. Swine pork chop ball tip pork shoulder ham hock
					pig chicken boudin jowl beef ribs. Boudin spare ribs biltong
					jerky ball tip pastrami buffalo picanha ground round swine
					chuck ribeye tongue beef capicola. Pig frankfurter kielbasa
					ball tip, tongue salami meatball prosciutto swine pork loin
					short ribs. Capicola chicken ball tip shankle, alcatra flank
					hamburger short ribs pork belly jowl brisket cow rump boudin
					shoulder.
				</p>
				<p className='home-body-paragraph'>
					Burgdoggen tongue meatloaf ham hock cupim. Filet mignon
					buffalo flank sirloin ribeye shankle. Ham hock pastrami
					short loin chuck pig leberkas shank. Drumstick chicken kevin
					frankfurter prosciutto shankle tail tongue spare ribs
					turducken pancetta venison short loin rump sausage. Meatball
					meatloaf swine pastrami buffalo chuck alcatra pork loin
					pancetta.
				</p>
				<p className='home-body-paragraph'>
					Sirloin chuck short loin capicola landjaeger pork loin, pig
					biltong buffalo tail shoulder pancetta ball tip. Cow swine
					flank spare ribs chislic. Prosciutto ham hock flank
					leberkas, pork belly tail biltong. Alcatra kevin bresaola,
					pig tri-tip andouille picanha sirloin t-bone. Tail jerky
					meatloaf, rump leberkas ribeye shoulder short loin salami.
					Pork belly pork loin short loin shankle tongue jowl.
				</p>
				<h3 className='home-body-title'>
					How do we use your information?
				</h3>
				<p className='home-body-paragraph'>
					Gummies jelly chocolate bar candy canes bear claw cookie
					jelly beans. Marshmallow bear claw toffee sugar plum sugar
					plum macaroon. Jelly-o candy gummi bears sweet ice cream.
					Pie cotton candy jelly tiramisu lollipop.
				</p>
				<p className='home-body-paragraph'>
					Toffee caramels topping apple pie sugar plum wafer sugar
					plum. Croissant tart gingerbread chocolate cake pudding
					lemon drops cheesecake marshmallow caramels. Gingerbread ice
					cream macaroon marshmallow caramels lollipop gummies sugar
					plum ice cream. Cake sesame snaps fruitcake gummi bears
					tiramisu lemon drops.
				</p>
				<p className='home-body-paragraph'>
					Sweet roll biscuit chocolate topping caramels. Wafer tart
					dragée dragée tiramisu marzipan danish. Jelly beans icing
					cookie macaroon carrot cake tootsie roll sesame snaps powder
					biscuit. Cotton candy chocolate croissant dessert pie
					fruitcake.
				</p>
				<h4 className='home-body-subtitle'>
					Do we use cookies and other tracking technologies?
				</h4>
				<p className='home-body-paragraph'>
					There is no strife, no prejudice, no national conflict in
					outer space as yet. Its hazards are hostile to us all. Its
					conquest deserves the best of all mankind, and its
					opportunity for peaceful cooperation many never come again.
				</p>
				<h4 className='home-body-subtitle'>
					How long do we keep your information?
				</h4>
				<p className='home-body-paragraph'>
					It is for these reasons that I regard the decision last year
					to shift our efforts in space from low to high gear as among
					the most important decisions that will be made during my
					incumbency in the office of the Presidency.
				</p>
				<h4 className='home-body-subtitle'>
					How do we keep your information safe?
				</h4>
				<p className='home-body-paragraph'>
					We choose to go to the moon. We choose to go to the moon in
					this decade and do the other things, not because they are
					easy, but because they are hard, because that goal will
					serve to organize and measure the best of our energies and
					skills.
				</p>

				<h3 className='home-body-title'>
					What are your privacy rights?
				</h3>
				<p className='home-body-paragraph'>
					Make it look like Apple i was wondering if my cat could be
					placed over the logo in the flyer, for I like it, but can
					the snow look a little warmer can you lower the price for
					the website? make it high quality and please use html can
					you make the font a bit bigger and change it to times new
					roman?
				</p>
				<h4 className='home-body-subtitle'>
					How can you contact us about this policy?
				</h4>
				<p className='home-body-paragraph'>
					Jazz it up a little bit make the picture of the cupcake look
					delicious make the purple more well, purple-er it looks so
					empty add some more hearts can you add a bit of pastel pink
					and baby blue because the purple alone looks too fancy okay
					can you put a cute quote on the right side of the site?
					<ol>
						<li>
							Make it original can it be more retro make it pop
						</li>
						<li>
							The website doesn't have the theme i was going for.
							That will be a conversation piece.
						</li>
						<li>Oh, and please put pictures of cats everywhere</li>
					</ol>
				</p>
			</div>
		</div>
	);
};

export default Home;
