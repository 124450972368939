import { ReactComponent as ReaktorLogo } from '../assets/logo.svg';
import { Link } from 'react-router-dom';

const Nav = () => {
	return (
		<nav className='site-nav-bar'>
			<div className='left-nav-section'>
				<Link
					to='/'
					className='home-link-logo'
				>
					<ReaktorLogo></ReaktorLogo>
				</Link>
				<Link
					to='/'
					className='reaktor-button home-link-button'
				>
					Home
				</Link>
				<button className='reaktor-button'>
					Resources <span className='upside-down-caret' />
				</button>
				<button className='reaktor-button'>
					Products <span className='upside-down-caret' />
				</button>
				<button className='reaktor-button'>
					Pricing <span className='upside-down-caret' />
				</button>
			</div>
			<div className='ghost-nav-section' />
			<div className='right-nav-section'>
				<Link
					to='/login'
					className='reaktor-button login-link'
				>
					Log In
				</Link>
				<Link
					to='/login'
					className='reaktor-button green-button login-link'
				>
					Sign up
				</Link>
			</div>
		</nav>
	);
};

export default Nav;
